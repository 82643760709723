import { useRef } from 'react';
import { Link } from 'react-router-dom';
import {logmein} from '../utils/firebase';

const Login = (props)=> {
    const form = useRef();

    const logme = (e)=> {
        e.preventDefault();
        logmein(form.current[0].value, form.current[1].value, props.setUser);
    }

    return (
        <div>
            <form ref={form} onSubmit={logme}>
                <input type="email" name="email"></input>
                <input type="password" name="password"></input>
                <button>LOGIN</button>
                <p>{props.user ? <Link to ="/dashboard" >IR AL DASHBOARD</Link> : "LOGIN PARA ENTRAR"}</p>
            </form>
        </div>
    );
}

export default Login;