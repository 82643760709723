// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, child, get, set, update  } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvSYSM2ePeeXh7tWIrV10nsjTAKAJGmHk",
  authDomain: "cnbjj2022.firebaseapp.com",
  databaseURL: "https://cnbjj2022-default-rtdb.firebaseio.com",
  projectId: "cnbjj2022",
  storageBucket: "cnbjj2022.appspot.com",
  messagingSenderId: "1073566571717",
  appId: "1:1073566571717:web:1ebe1cff374a78b6ea24ac",
  measurementId: "G-RKPVCRYKY4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const logmein = (email, password, updateuser)=> {
    const auth = getAuth();
    let result = 'not-logged-in';
    
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      result = 'ok';
      console.log(result);
      updateuser(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      result = `${errorCode} ${errorMessage}`;
      console.log(result);
    });
}


const dbRef = ref(getDatabase(app));

export const getdb = (updatedb)=> {
  get(child(dbRef, 'competidores/')).then((snapshot) => {
    if (snapshot.exists()) {
      //console.log(snapshot.val());
      updatedb(snapshot.val());
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
}

export const updateAtleta = (fireuser_udid)=> {
  const db = getDatabase(app);
  update(ref(db, '/competidores/'+fireuser_udid+'/tournaments/2022/SL01'), {
    cat_status: 'approved'
  })
  .then(() => {
    alert('ACTUALIZADO EXITOSAMENTE');
  })
  .catch((error) => {
    alert('OCURRIO UN ERROR');
  });
}