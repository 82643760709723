import { Link, NavLink } from "react-router-dom";

const Home = ()=> {
    return (
        <div>
            <h1>Bienvenido</h1>
            <p>Inicia sesión para empezar</p>
            <Link to='/login'>
                LOGIN
            </Link>
        </div>
    );
}
export default Home;