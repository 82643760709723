import { Link } from "react-router-dom";
import {getdb} from "../utils/firebase";
import React from "react";
import AtletaItem from "../components/AtletaItem";
import { useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";

const Dashboard = (props)=> {
    const user = props.user;
    const db = props.db;
    if(db){
        //console.log({db});
    }
    else getdb(props.setDB);

    let totalActivosTorneo = 0;
    let totalPagadosTorneo = 0;

    let totalFEMTorneo = 0;
    let totalMASTorneo = 0;

    let totalBlancoTorneo = 0;
    let totalAzulTorneo = 0;
    let totalVioletaTorneo = 0;
    let totalMarronTorneo = 0;
    let totalNegroTorneo = 0;

    let totalInfantilTorneo = 0;
    let totalAdultoTorneo = 0;
    let totalMasterTorneo = 0;

    let totalGalo = 0;
    let totalPluma = 0;
    let totalPena = 0;
    let totalLeve = 0;
    let totalMedio = 0;
    let totalMedioPesado = 0;
    let totalPesado = 0;
    let totalSuperPesado = 0;
    let totalPesadisimo = 0;

    let currentCat = '';

    let csvData = [["email", "nombre", "documento", "equipo", "profesor", "sexo", "edad", "faixa", "peso", "pago"]];
  
    function populateData(){
        const titles = [];
        const children = Object.entries(db).map( (val) => {
            if(val[1].tournaments) {
                if(val[1].tournaments['2022']){
                    if(val[1].tournaments['2022']['SL01']){
                        totalActivosTorneo++;
                        if(val[1].tournaments['2022']['SL01'].cat_status === 'approved'){
                            totalPagadosTorneo++;

                            if(val[1].sexo === 'F')totalFEMTorneo++;
                            if(val[1].sexo === 'M')totalMASTorneo++;

                            if(val[1].faixa === 'blanca')totalBlancoTorneo++;
                            if(val[1].faixa === 'azul')totalAzulTorneo++;
                            if(val[1].faixa === 'violeta')totalVioletaTorneo++;
                            if(val[1].faixa === 'marron')totalMarronTorneo++;
                            if(val[1].faixa === 'negra')totalNegroTorneo++;

                            csvData.push([val[1].email, val[1].nombre, val[1].documento, val[1].equipo, "profesor", "sexo", val[1].edad, val[1].faixa, val[1].peso, "pagoOK"])
                            
                            return <AtletaItem key={val[0]} data={val} adminOptions={false}/>
                        }
                    }
                }
            }
        });
        titles.push(<div key="title_activos"><h2>TOTAL ACTIVOS EN TORNEO SL: {totalActivosTorneo}</h2></div>);
        titles.push(<div key="title_pagados"><h2>TOTAL PAGADOS EN TORNEO SL: {totalPagadosTorneo}</h2></div>);
        titles.push(<div key="title_pagados_fem">TOTAL PAGADOS FEM SL: {totalFEMTorneo}</div>);
        titles.push(<div key="title_pagados_mas">TOTAL PAGADOS MASC SL: {totalMASTorneo}<br></br><br></br></div>);
        titles.push(<div key="title_pagados_blanco">TOTAL PAGADOS BLANCO SL: {totalBlancoTorneo}</div>);
        titles.push(<div key="title_pagados_azul">TOTAL PAGADOS AZUL SL: {totalAzulTorneo}</div>);
        titles.push(<div key="title_pagados_violeta">TOTAL PAGADOS VIOLETA SL: {totalVioletaTorneo}</div>);
        titles.push(<div key="title_pagados_marron">TOTAL PAGADOS MARRON SL: {totalMarronTorneo}</div>);
        titles.push(<div key="title_pagados_negra">TOTAL PAGADOS NEGRA SL: {totalNegroTorneo}<br></br><br></br></div>);
        return (
            <div className="dashboard-content">
                {titles}
                {children}
            </div>
        );
    }

    const [results, setResutls] = useState();

    const searchAtleta = (event) => {
        const atletasSearch = [];
        event.preventDefault();
        
        Object.entries(db).map( (val) => {
            if(((val[1].nombre).toLowerCase()).includes((event.target[0].value).toLowerCase()) || ((val[1].email).toLowerCase()).includes((event.target[0].value).toLowerCase()) || ((val[1].celular).toLowerCase()).includes((event.target[0].value).toLowerCase()) || ((val[1].documento).toLowerCase()).includes((event.target[0].value).toLowerCase()) ){
                console.log(val[1].nombre);  
                atletasSearch.push(<AtletaItem key={val[0]} data={val} adminOptions={true} />);
            }
        });

        setResutls(atletasSearch);
    }

    const searchAtleta2 = (event) => {

        if((event.target.value).length < 5)return;
        const atletasSearch = [];
        
        Object.entries(db).map( (val) => {
            if(((val[1].nombre).toLowerCase()).includes((event.target.value).toLowerCase()) || ((val[1].email).toLowerCase()).includes((event.target.value).toLowerCase()) || ((val[1].celular).toLowerCase()).includes((event.target.value).toLowerCase()) || ((val[1].documento).toLowerCase()).includes((event.target.value).toLowerCase()) ){
                console.log(val[1].nombre);  
                atletasSearch.push(<AtletaItem key={val} data={val} />);
            }
        });

        setResutls(atletasSearch);
    }
    
    return (
        <div className="dashboard">
            <div className="dashboard-main">
                <div>{user ? 'Bienvenido' : <Link to ="/login" >LOGIN TO START</Link> }</div>
                <h1>TOTAL ATLETAS</h1>
                <div>{db ? <h2>ATLETAS SAN LUIS</h2> : 'loading'}</div>
                <CSVLink data={csvData}>Descargar Planilla</CSVLink>
                <div>{db ?  populateData()  : 'loading'}</div>
            </div>
            <div className="dashboard-search-panel">
                <form onSubmit={searchAtleta}>
                    <label>
                    Buscar:
                    <input
                        type="text"
                        name="username"
                        //onChange={searchAtleta2}
                    />
                    </label>
                    <button type="submit">Submit</button>
                </form>
                <div className="dashboard-search-panel-results">
                    {results ? results : 'no results'}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;