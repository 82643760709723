import { updateAtleta } from "../utils/firebase";

export default function AtletaItem(props) {

  function aprobarAtleta(){
    console.log("Aprobar atleta");
    updateAtleta(props.data[0]);
  }

  return (
    <div className="atleta-item">
      <h4 className="atleta-item-name">{props.data[1].nombre}</h4>
      <div className="atleta-item-header-icons">
        <div className="atleta-item-header-icons__image">
          <img alt="cnbjj" src={`https://cnbjj.ar/img/teams/${props.data[1].equipo}.jpg`} />
        </div>

        <div className="atleta-item-header-icons__faixa">
          <div className={props.data[1].faixa}></div>
        </div>

        <div className="atleta-item-header-icons__sex">
          {props.data[1].sexo}
        </div>
      </div>
      <div className="atleta-item-header">  
        <div className="atleta-item-header-titles">
          <h4>DNI: {props.data[1].documento}</h4>
          <p>{props.data[1].peso}</p>
          <p>{props.data[1].edad}</p>
        </div>
      </div>
      <div className="atleta-item-box">
        <p>{props.data[1].celular}</p>
        <p>{props.data[1].email}</p>
        <p>{props.data[1].otroequipo}</p>
      </div>
      {props.adminOptions && <div className="atleta-item-admin"> <button onClick={aprobarAtleta}>APROBAR CATEGORIA</button> </div>}
    </div>
  );
}