import './App.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import { useState } from 'react';
import Dashboard from './pages/Dashboard';

function App() {
  const [db, setDB] = useState();
  const [user, setUser] = useState();
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element= {<Home/>} />
        <Route path='/login' element= {<Login user={user} setUser={setUser}/>} />
        <Route path='/dashboard' element= {<Dashboard db={db} setDB={setDB} user={user}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
